<template>
  <div style="width: 100%">

    <b-container class="main-content-container px-4" fluid>
      <b-row class="page-header py-4" no-gutters>
        <b-col class="text-center text-sm-left mb-sm-0" col sm="12">
          <h3 class="page-title">Import and compare credits accounts overview </h3>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="main-content-container px-4 pb-4" fluid>
      <b-card>
        <div class="row">
          <div class="col-lg-4"></div>
          <div class="col-lg-3">
            <b-form-file v-model="file" @change="readFile" ref="input"/>
          </div>
          <div class="col-lg-3 ">
            <b-button @click="compareExcel" class="btn-primary btn-block">GET
              INFORMATION FROM DATABASE AND COMPARE
            </b-button>
          </div>
          <div class="col-lg-2">
            <router-link :to="{name: 'credit-dashboard'}" variant="danger" class="btn btn-danger btn-block">
              GO BACK
            </router-link>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <b-table class="datatable" striped hover responsive small :items="array_data"
                     :fields="columns">
              <template #cell(REMAINING)="{item}">
                <span class="bolder" v-html="item.REMAINING"></span>
              </template>
              <template #cell(RECEIVED)="{item}">
                <span class="bolder" v-html="item.RECEIVED"></span>
              </template>
              <template #cell(USED)="{item}">
                <span class="bolder" v-html="item.USED"></span>
              </template>
            </b-table>
          </div>
        </div>
      </b-card>
    </b-container>

  </div>
</template>

<script>
import * as XLSX from "xlsx";
import {get} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "compareExcel",
  mixins: [show_alert_mixin],
  data: () => ({
    file: null,
    columns: [
      {key: 'COMPANY_NAME', label: 'NAME', sortable: true},
      {key: 'EMAIL', label: 'EMAIL', sortable: true},
      {key: 'COMPANY_COUNTRY', label: 'COUNTRY', sortable: true},
      {key: 'RECEIVED', label: 'RECEIVED', sortable: true},
      {key: 'USED', label: 'USED', sortable: true},
      {key: 'REMAINING', label: 'REMAINING', sortable: true},
    ],
    data_excel_formatted: [],
    array_data: [],
    accounts_data: [],
  }),
  methods: {
    async readFile(e) {
      try {
        this.clear_data()
        const file = e.target.files[0];
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          const reader = new FileReader();
          reader.onloadend = () => {
            const arrayBuffer = reader.result;
            const workbook = XLSX.read(arrayBuffer, {type: 'binary'});
            this.data_excel_formatted = XLSX.utils.sheet_to_json(workbook.Sheets['ACCOUNT OVERVIEW'])
            if (this.data_excel_formatted.length && this.data_excel_formatted[0].COMPANY_COUNTRY) {
              this.array_data = JSON.parse(JSON.stringify(this.data_excel_formatted))
              this.array_data.forEach(item => {
                item.REMAINING = this.formatNumber(item.REMAINING)
                item.RECEIVED = this.formatNumber(item.RECEIVED)
                item.USED = this.formatNumber(item.USED)
              })
            } else {
              this.file = null
              this.showAlertNotification('Incorrect file', 'error')
              return
            }
          }
          reader.readAsBinaryString(file)
          this.showAlertNotification(`File uploaded and ready to compare`)
        } else {
          this.showAlertNotification('File not permitted', 'error')
        }
      } catch (e) {
        this.showAlertNotification('Something went wrong', 'error')
      }
    },
    async compareExcel() {
      await this.loadUsersFromDatabase()
      await this.compare_data()
      this.file = null
    },
    async loadUsersFromDatabase() {
      this.accounts_data = [];
      let {data} = await get(`credit/index`, null, true);
      if (Number(data.statusCode) === Number(200)) {
        this.accounts_data = data.message.map(el => {
          return {
            COMPANY_COUNTRY: el.country,
            COMPANY_NAME: el.fullName,
            EMAIL: el.email,
            RECEIVED: Number(el.bought.toFixed(2)),
            REMAINING: Number((el.bought + el.used).toFixed(2)),
            USED: Number(el.used.toFixed(2))
          }
        });
      }
    },
    async compare_data() {
      if (this.data_excel_formatted.length && this.accounts_data.length && this.file) {
        this.file = null;
        const tempArray = JSON.parse(JSON.stringify(this.accounts_data))
        this.accounts_data = tempArray.map((item) => {
          const temp = this.data_excel_formatted.find(x => x.EMAIL === item.EMAIL)
          if (temp) {
            item._rowVariant = this.are_equals_values(item.REMAINING, temp.REMAINING) && this.are_equals_values(item.RECEIVED, temp.RECEIVED) && this.are_equals_values(item.USED, temp.USED) ? '' : 'warning'
            item.REMAINING = this.are_equals_values(item.REMAINING, temp.REMAINING) ? this.formatNumber(item.REMAINING) : `TVS: ${this.formatNumber(item.REMAINING)}<br>FILE: ${this.formatNumber(temp.REMAINING)}`
            item.RECEIVED = this.are_equals_values(item.RECEIVED, temp.RECEIVED) ? this.formatNumber(item.RECEIVED) : `TVS: ${this.formatNumber(item.RECEIVED)}<br>FILE: ${this.formatNumber(temp.RECEIVED)}`
            item.USED = this.are_equals_values(item.USED, temp.USED) ? this.formatNumber(item.USED) : `TVS: ${this.formatNumber(item.USED)}<br>FILE: ${this.formatNumber(temp.USED)}`
          } else {
            item._rowVariant = 'danger'
          }
          return item
        })
        this.array_data = JSON.parse(JSON.stringify(this.accounts_data))
        let message = 'The data was successfully compared.'
        if (this.data_excel_formatted.length !== this.accounts_data.length) {
          message += `<br><br> <span class="text-danger">However, the lengths are not equal. </span><br><br>  Excel: ${this.data_excel_formatted.length} users <br><br> TVS: ${this.accounts_data.length} users`
        } else {
          message += `<br><br>Excel: ${this.data_excel_formatted.length} users <br> TVS: ${this.accounts_data.length} users`
        }
        this.showAlertNotification(message)
      } else {
        this.clear_data()
        this.showAlertNotification('No data uploaded to compare or incorrect file', 'error')
      }
    },
    are_equals_values(value1, value2) {
      return Number(value1) === Number(value2)
    },
    clear_data() {
      this.data_excel_formatted = [];
      this.accounts_data = [];
      this.array_data = [];
    },
    formatNumber(value) {
      const formater = new Intl.NumberFormat('sfb', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      });
      const format = formater.format(value)
        .replace('€', '€ ');
      return format
    }
  }
}
</script>

<style scoped lang="scss">
.bolder {
  font-weight: bold;
}
</style>
